/* eslint-disable react/jsx-no-constructed-context-values */
// TODO: I don't like the above eslint suppression, but need to address later
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { debounce, isEqual } from 'lodash'
import resolveConfig from 'tailwindcss/resolveConfig'
import { TailwindConfig } from 'tailwindcss/tailwind-config'
import tailwindConfig from '../../../tailwind.config.js'
import {
  Breakpoints,
  ConvertedBreakpoints,
  defaultBreakpoints,
  StateContext,
  TwConfigBreakpoints,
} from './StateContext'

const fullConfig = resolveConfig(tailwindConfig as unknown as TailwindConfig)

interface Props {
  children: React.ReactNode
}

export const getBreakpointValue = (value: string): number => (
  +value.slice(0, value.indexOf('px'))
)

const StateProvider: FunctionComponent<Props> = ({
  children,
}: Props) => {
  const [breakpoints, setBreakpoints] = useState<Breakpoints>(defaultBreakpoints)

  // convert string ('100px') to numbers
  const convertedBreakpoints: ConvertedBreakpoints = useMemo(() => {
    // need to cast this here
    // not sure it's a good idea
    const screens = fullConfig.theme.screens as TwConfigBreakpoints

    return Object.entries(screens).reduce((
      prev,
      entry,
    ) => {
      const next = {
        ...prev,
        [entry[0]]: getBreakpointValue(entry[1]),
      }
      return next
    }, {} as ConvertedBreakpoints)
  }, [fullConfig])

  useEffect(() => {
    // this doesn't work outside of the useEffect
    // as the breakpoints state isn't updated for
    // comparison
    const handleSetBreakpoints = debounce((): void => {
      const nextBreakpoints = Object.entries(convertedBreakpoints).reduce((prev, entry) => {
        const next = {
          ...prev,
          [entry[0]]: window.innerWidth >= entry[1],
        }
        return next
      }, {} as Breakpoints)

      // update state if different
      if (!isEqual(breakpoints, nextBreakpoints)) {
        setBreakpoints(nextBreakpoints)
      }
    }, 100)

    handleSetBreakpoints()

    window.addEventListener('resize', handleSetBreakpoints)
    return () => {
      window.removeEventListener('resize', handleSetBreakpoints)
    }
  }, [breakpoints])

  return (
    <StateContext.Provider value={{
      breakpoints,
    }}
    >
      {children}
    </StateContext.Provider>
  )
}

export default StateProvider
