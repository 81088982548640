import {
  STORY_SORT_ORDER,
  CONTRIBUTION_SORT_ORDER,
  SOCIAL_SORT_ORDER,
  ANCHOR_SORT_ORDER,
  EXHIBITION_CONTENT_SORT_ORDER,
  EXHIBITION_SORT_ORDER,
} from './constants'
import { SocialLink } from './types'

export const linkSort = (a: SocialLink, b: SocialLink): number => (
  SOCIAL_SORT_ORDER.indexOf(a.type) - SOCIAL_SORT_ORDER.indexOf(b.type)
)

export const contributionSort = (a, b): number => (
  CONTRIBUTION_SORT_ORDER.indexOf(a) - CONTRIBUTION_SORT_ORDER.indexOf(b)
)

export const exhibitionSort = (a, b): number => (
  EXHIBITION_SORT_ORDER.indexOf(a) - EXHIBITION_SORT_ORDER.indexOf(b)
)

export const storySort = (a, b): number => (
  STORY_SORT_ORDER.indexOf(a) - STORY_SORT_ORDER.indexOf(b)
)

export const anchorSort = (a, b): number => (
  ANCHOR_SORT_ORDER.indexOf(a) - ANCHOR_SORT_ORDER.indexOf(b)
)

export const exhibitionContentSort = (a, b): number => (
  EXHIBITION_CONTENT_SORT_ORDER.indexOf(a) - EXHIBITION_CONTENT_SORT_ORDER.indexOf(b)
)
