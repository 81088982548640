import styled from '@emotion/styled'

export const FooterTop = styled.div`
  height: 50px;
  position: relative;
  z-index: 10;
  overflow: hidden;

  &.home {
    // This is here for the landing page
    margin-top: -50px;
  }
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    min-width: 1200px;
    clip-path: url(#footerClip);
    bottom: 0;
    left: 0;
    right: 0;

    &::before {
      height: 100%;
      background: black;
    }
  }

  &::before,
  .bg::before {
    content: '';
    display: block;
    width: 100vw;
  }

  &::before {
    height: 4px;
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    background: black;
  }
`

export const FooterLinksSection = styled.div`
  border-bottom: 0.75px solid #24242b;
  &.home {
    border-top: 0.75px solid #24242b;
  }
`
export const FooterLegal = styled.div`
  font-size: 7px;
  font-weight: 600;
  .copy {
    color: #90909f;
  }
`
