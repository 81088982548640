import React from 'react'

export type TwConfigBreakpoints = {
  sm: string
  md: string
  lg: string
  xl: string
  '2xl': string
}

export type ConvertedBreakpoints = {
  [x in keyof TwConfigBreakpoints]: number
}

export type Breakpoints = {
  [x in keyof TwConfigBreakpoints]: boolean
}

export interface StateContextT {
  breakpoints: Breakpoints
}

// default to mobile
export const defaultBreakpoints: Breakpoints = {
  sm: true,
  md: false,
  lg: false,
  xl: false,
  '2xl': false,
}

export const defaultContext: StateContextT = {
  breakpoints: defaultBreakpoints,
}

export const StateContext = React.createContext<StateContextT>(defaultContext)
