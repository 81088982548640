import React from 'react'
import styled from '@emotion/styled'
import classNames from 'classnames'
import mwLogo from '../static/assets/meowwolf.svg'
import mwMark from '../static/assets/mw-mark.svg'
import ticketsIcon from '../static/assets/ticket-alt-solid.svg'

const TicketsButton = styled.a`
  font-size: 14px;
  img {
    width: 17px;
    @media(min-width: 768px) {
      margin-right: 8px;
    }
  }
`

const Navbar: React.FunctionComponent = () => (
  <div
    id="navbar"
    className={
      classNames(
        'md:h-16',
        'bg-black',
        'text-white',
        'grid',
        'navbar-cols-1',
        'md:navbar-cols-3',
        'text-base',
        'w-full',
        'z-50',
        'top-0',
        'px-4',
        'py-[0.8rem]',
      )
    }
  >
    {/* Logo Large */}
    <span className="hidden md:inline-block">
      <a href="https://meowwolf.com">
        <img
          src={mwLogo}
          className="cursor-pointer"
          alt="Meow Wolf Logo Large"
        />
      </a>
    </span>
    {/* Logo small */}
    <span className="md:hidden flex items-center">
      <a href="https://meowwolf.com">
        <img
          src={mwMark}
          width={25}
          height={25}
          className="cursor-pointer"
          alt="Meow Wolf Logo Small"
        />
      </a>
    </span>
    <div
      id="primary-nav"
      className={
        classNames(
          'flex',
          'flex-row',
          'justify-between',
          'items-center',
          'flex-grow',
        )
      }
    >
      <a className="navlink" href="https://meowwolf.com/about">
        About
      </a>
      <a className="navlink" href="https://meowwolf.com/visit">
        Visit
      </a>
      <a
        className="navlink"
        href="https://meowwolf.com/blob"
      >
        Blob
      </a>
      <a className="navlink" href="https://shop.meowwolf.com/">
        Merch
      </a>
    </div>
    <div
      id="secondary-nav"
      className={
        classNames(
          'flex',
          'flex-row',
          'justify-end',
          'items-center',
          'my-2',
          'md:my-0',
          'md:place-self-auto',
          'tracking-wider',
          'font-semibold',
          'place-self-end',
        )
      }
    >
      <TicketsButton
        href="https://tickets.meowwolf.com/"
        className={classNames(
          'h-8',
          'uppercase',
          'bg-pink',
          'text-white',
          'py-[5px]',
          'px-[15px]',
          'rounded-full',
          'flex',
          'items-center',
          'justify-center',
          'tracking-[1px]',
        )}
      >
        <img
          src={ticketsIcon}
          alt="Link to tickets page"
          className="-rotate-45"
        />
        <span className="hidden md:block">
          Tickets
        </span>
      </TicketsButton>
    </div>
  </div>
)

export default Navbar
