/** @jsx jsx */
/** @jsxFrag */
import React, { FunctionComponent } from 'react'
import { Link } from 'gatsby'
import { css, jsx } from '@emotion/react'
import classNames from 'classnames'
import { useLocation } from '../hooks/useLocation'
import { VENUES } from '../utils/types'

const SubNavbar: FunctionComponent = () => {
  const { router } = useLocation()
  const exhibitionInPath = router.pathname === VENUES.SAF.path
    || router.pathname === VENUES.DEN.path
    || router.pathname === VENUES.LVS.path
    || router.pathname === '/'

  const personPath = router.pathname.indexOf('/people') > -1

  const sharedStyles = css`
  &:before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  `
  return (
    <div className={[
      'bg-pink',
      'py-3',
      'space-x-8',
      'px-8',
      'uppercase',
      'text-white',
      'sticky',
      'top-0',
      'z-50',
    ].join(' ')}
    >
      <Link
        className={classNames('inline-block', {
          'font-semibold': exhibitionInPath,
          'font-normal': !exhibitionInPath,
        })}
        to="/"
        title="Exhibitions"
        css={sharedStyles}
      >
        Exhibitions
      </Link>
      <Link
        className={classNames('inline-block', {
          'font-semibold': personPath,
          'font-normal': !personPath,
        })}
        to="/people"
        title="People"
        css={sharedStyles}
      >
        People
      </Link>
    </div>
  )
}
export default SubNavbar
