import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import instagram from '../../static/assets/instagram-footer.svg'
import facebook from '../../static/assets/facebook-footer.svg'
import twitter from '../../static/assets/twitter-footer.svg'
import youtube from '../../static/assets/youtube-footer.svg'
import meowwolfLogo from '../../static/assets/meowwolf-logo-stacked.svg'
import mwwcColorLogo from '../../static/assets/mwwc-logo.svg'
import bcorp from '../../static/assets/bcorp.svg'
import { FooterLegal, FooterTop, FooterLinksSection } from './styles'
import { useLocation } from '../../hooks/useLocation'
import { VENUE_CITIES } from '../../utils/types'
import { arrayToReadableList } from '../../utils'
import mwMark from '../../static/assets/mw-mark.svg'

const Footer: FunctionComponent = () => {
  const { router } = useLocation()
  const footerAdvert = useStaticQuery<GatsbyTypes.getHomePageTextQuery>(graphql`
  query getHomePageText {
    message: airtable(
      table: {eq: "Dynamic Content"}
      data: {Page_Title: {eq: "home_page_text"}}
    ) {
      data {
        Content
      }
    }
    exhibitionCount: allAirtable(filter: {table: {eq: "Exhibitions"}}) {
      totalCount
    }
    projectCount: allAirtable(filter: {table: {eq: "Projects"}}) {
      totalCount
    }
    peopleCount: allAirtable(
      filter: {table: {eq: "People"}, data: {Display_on_Site: {eq: "True"}}}
    ) {
      totalCount
    }
  }
  
  `)

  // Only Display Footer Advertisement on Homepage
  const isHomePage = router?.pathname === '/'
  let displayMessage
  if (isHomePage) {
    const message = footerAdvert.message?.data?.Content
    const exhibitionCount = footerAdvert.exhibitionCount.totalCount
    const projectCount = footerAdvert.projectCount.totalCount
    const peopleCount = footerAdvert.peopleCount.totalCount
    displayMessage = (
      <div className="text-sm font-medium mb-2">
        {message}
        <div className="font-bold mt-5">
          This credits site celebrates the work of&nbsp;
          {peopleCount}
          &nbsp;collaborators and their&nbsp;
          {projectCount}
          &nbsp;projects across&nbsp;
          {exhibitionCount}
          &nbsp;permanent exhibitions in&nbsp;
          {arrayToReadableList(VENUE_CITIES.map((el) => el))}
          .
        </div>
      </div>
    )
  }
  const homePageClass = { home: isHomePage }
  return (
    <>
      <FooterTop
        className={classNames(homePageClass, 'footer-top')}
      >
        <div className="bg" />
      </FooterTop>
      <footer className="bg-black text-white pb-6">
        {isHomePage && (
          <>
            <div className="w-full md:w-1/2 mx-auto text-left md:text-center px-8 md:px-0 pt-3">{displayMessage}</div>
            <div className="flex justify-center my-8 space-x-8">
              <img src={meowwolfLogo} alt="Meow Wolf Logo" />
              <img src={mwwcColorLogo} alt="Meow Wolf Workers Collective" />
            </div>
          </>
        )}
        <FooterLinksSection
          className={classNames(
            'flex',
            'flex-col',
            'md:flex-row',
            'justify-between',
            'py-2',
            'md:pt-10',
            'px-8',
            'pb-4',
            homePageClass,
          )}
        >
          <ul className="flex flex-col md:flex-row font-bold space-y-4 md:space-y-0 0 mt-4 md:space-x-8 uppercase">
            <li>
              <a href="https://meowwolf.com/about" rel="noopener noreferrer">
                About
              </a>
            </li>
            <li>
              <a href="https://meowwolf.com/visit" rel="noopener noreferrer">
                Visit
              </a>
            </li>
            <li>
              <a href="https://meowwolf.com/blob" rel="noopener noreferrer">
                Blob
              </a>
            </li>
            <li>
              <a href="https://shop.meowwolf.com" rel="noopener noreferrer">
                Merch
              </a>
            </li>
          </ul>
          <ul className="flex flex-row font-bold space-x-8 justify-end items-center">
            <li>
              <a
                href="https://www.facebook.com/meowwolf.sf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Meow Wolf's Facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/meow__wolf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="Meow Wolf's Instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/MeowWolf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="Meow Wolf's Twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/meowwolf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="Meow Wolf's Youtube" />
              </a>
            </li>
            <li>
              <a
                href="https://bcorporation.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={bcorp} alt="B Corp. website" />
              </a>
            </li>
          </ul>
        </FooterLinksSection>
        <FooterLegal
          className={classNames(
            'flex',
            'flex-col',
            'sm:flex-row',
            'justify-around',
            'md:justify-end',
            'md:mr-13',
            'md:space-x-8',
            'pt-4',
            'px-2',
            'md:px-8',
            'items-center',
            'max-sm:gap-y-2',
          )}
        >
          <div className={classNames('max-sm:m-auto', 'md:mr-auto')}>
            <img
              src={mwMark}
              width={28}
              height={28}
              alt="Meow Wolf Logo Small"
            />
          </div>
          <div className="copy w-full sm:w-auto text-center sm:text-left ml-2">
            &copy; Meow Wolf, All Rights Reserved
          </div>
          <div className="font-semibold mx-auto w-1/2 sm:w-36 flex justify-around sm:justify-between">
            <a href="https://meowwolf.com/terms-of-use">Terms Of Use</a>
            <a href="https://meowwolf.com/privacy-policy">Privacy</a>
          </div>
        </FooterLegal>
      </footer>
      {/* Swoosh svg */}
      <svg width="0" height="0">
        <clipPath id="footerClip" clipPathUnits="objectBoundingBox">
          <path
            // eslint-disable-next-line max-len
            d="M 0,1.0093553 H 1 V 0.17975125 C 0.87013808,0.58019657 0.79683697,-0.07224131 0.63333357,0.01804174 0.4698315,0.10832479 0.4645835,0.72682082 0.31875,0.84478877 0.17291638,0.96278528 0.10763879,0.24329223 0,0.30666179 Z"
            fill="#000000"
          />
        </clipPath>
      </svg>
    </>
  )
}

export default Footer
