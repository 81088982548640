// SEO Related Vars
export const COMPANY_NAME = 'Meow Wolf'
export const CREATIVE_TEAM = 'Creative Team'
export const CREDITS = 'Credits'
export const ARTISTS_AND_CONTRIBUTORS = 'Artists & Contributors'
export const SITE_NAME = `${CREDITS} | ${COMPANY_NAME}`
export const CANONICAL_URL = 'https://credits.meowwolf.com'
// eslint-disable-next-line max-len
export const FALLBACK_SEO_DESCRIPTION = `Discover the artists and creative teams behind ${COMPANY_NAME}'s exhibitions. Works include sculpture, painting, fabrication, digital art, writing, film, and more.`

// SEO Related FNS
export const createSeoTitle = (leftStr: string, rightStr: string): string => (`${leftStr} | ${rightStr}`)
