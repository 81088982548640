import React, {
  createContext,
  FunctionComponent,
  useContext,
  useMemo,
} from 'react'
import { GatsbyLocation } from '../utils/types'

const LocationContext = createContext<{ router: GatsbyLocation | null }>({ router: null })

interface Props {
  children: React.ReactNode
  router: GatsbyLocation
}
export const LocationProvider: FunctionComponent<Props> = ({ children, router }: Props) => {
  const value = useMemo(() => ({ router }), [router])

  return (
    <LocationContext.Provider
      value={value}
    >
      {children}
    </LocationContext.Provider>
  )
}

export const useLocation = (): {router: GatsbyLocation | null} => {
  const {
    router,
  } = useContext(LocationContext)
  return {
    router,
  }
}
