module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    extend: {
      container: false,
      maxHeight: {
        140: '140px',
      },
      borderRadius: {
        20: '20px',
      },
      colors: {
        pink: '#EC0089',
        pinkLight: '#FAB1DB',
        purple: '#A531C3',
        gray: {
          50: '#f9f9f9',
          100: '#f3f3f3',
          200: '#e1e1e1',
          300: '#cfcfcf',
          400: '#aaaaaa',
          500: '#868686',
          600: '#797979',
          700: '#656565',
          800: '#505050',
          900: '#424242',
        },

      },
    },
  },
  plugins: [
    function ({ addComponents }) {
      addComponents({
        '.container': {
          maxWidth: '100%',
          '@screen sm': {
            maxWidth: '100%',
          },
          '@screen md': {
            maxWidth: '100%',
          },
          '@screen lg': {
            maxWidth: '1024px',
          },
          '@screen xl': {
            maxWidth: '1280px',
          },
          '@screen 2xl': {
            maxWidth: '1536px',
          },
        },
      })
    },
  ],
}
