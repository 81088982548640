import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import React, { Fragment, FunctionComponent, useEffect } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import closeIcon from '../images/closeIcon.svg'

const lsName = 'mw-credits-alert-dismissed'

const ToastWrapper: FunctionComponent = () => {
  const displayToast = process.env.GATSBY_DISPLAY_TOAST === 'true'
  const initialToast = useStaticQuery<GatsbyTypes.getSiteWideNotificationQuery>(graphql`
  query getSiteWideNotification {
    message: airtable(
      table: {eq: "Dynamic Content"}
      data: {Page_Title: {eq: "site_wide_notification"}}
    ) {
      data {
        Content
      }
    }
  }
  `)
  const message = initialToast.message?.data?.Content

  useEffect(() => {
    const hasDisplayed = localStorage.getItem(lsName)

    if (displayToast && !hasDisplayed) {
      toast.custom((t) => (
        <div
          className={classNames(
            {
              'fade-in': t.visible,
            },
            {
              'fade-out': !t.visible,
            },
            'drop-shadow-md',
            'filter',
            'bg-pink',
            'text-white',
            'rounded-lg',
            'w-full',
            'md:w-1/2',
            'relative',
            'p-5',
            'pr-8',
          )}
        >
          {message}
          <button
            className="p-2 absolute right-0 top-0"
            title="Dismiss notification"
            type="button"
            onClick={() => {
              toast.dismiss(t.id)
              localStorage.setItem(lsName, 'true')
            }}
          >
            <img src={closeIcon} alt="close toast" />
          </button>
        </div>
      ))
    }
  }, [])

  if (!message) return null

  return (
    <Fragment>
      {' '}
      <Toaster
        toastOptions={{
          position: 'top-right',
          duration: Infinity,
        }}
      />
    </Fragment>
  )
}

export default ToastWrapper
