exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-people-tsx": () => import("./../../../src/pages/people.tsx" /* webpackChunkName: "component---src-pages-people-tsx" */),
  "component---src-templates-anchor-tsx": () => import("./../../../src/templates/Anchor.tsx" /* webpackChunkName: "component---src-templates-anchor-tsx" */),
  "component---src-templates-exhibition-content-tsx": () => import("./../../../src/templates/ExhibitionContent.tsx" /* webpackChunkName: "component---src-templates-exhibition-content-tsx" */),
  "component---src-templates-exhibition-tsx": () => import("./../../../src/templates/Exhibition.tsx" /* webpackChunkName: "component---src-templates-exhibition-tsx" */),
  "component---src-templates-person-tsx": () => import("./../../../src/templates/Person.tsx" /* webpackChunkName: "component---src-templates-person-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/Project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */)
}

